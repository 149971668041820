.about_hero h3 {
  font-size: 28px;
}

.about_hero p {
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
}

.about_hero .lead {
  font-size: 20px;
  font-weight: 600;
}
.about_hero .box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.about_hero .box img {
  border-radius: 2rem;
  -webkit-border-radius: 2rem;
  -moz-border-radius: 2rem;
  -ms-border-radius: 2rem;
  -o-border-radius: 2rem;
}

.text_padding {
  padding: 0 0 0 10px;
}
.btn-secondary {
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}
.btn-secondary:hover {
  background: var(--color-secondary);
  color: #fff;
}
.d_f {
  display: flex;
  justify-content: center;
}
.d_f .btn {
  width: 10rem;
  padding: 1rem;
}

.f_center {
  display: flex;
  align-items: flex-start;
}
