.car_wash p {
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
}

.car_wash .lead {
  font-size: 18px;
  font-weight: 500;
}

.car_wash .body {
  text-align: right;
  padding: 0 1rem;
}
.box-image-2 {
  min-height: 520px;
}

.box-image-2 .media {
  width: 100%;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.box-image-2 .media img {
  height: 100%;
}

.box-image-2 .body .title {
  font-size: 20px;
}
.box-image-2 .body p {
  font-size: 16px;
  font-weight: 500;
}

.dropdown-btn {
}

.car_hero p {
  padding-right: 2rem;
}
@media (max-width: 765px) {
  .car_hero {
    padding-right: 1rem;
    display: flex;
    flex-direction: column-reverse;
  }
}
