.bg {
  background-color: rgba(0, 160, 222, 0.1);
}
.contact_section {
  padding: 4rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contact_section .container {
  border: 1px solid #ddd;
  border-radius: 1rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
  padding: 2rem;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1);
}

.contact_section .section-heading {
  padding: 0;
}
.form_content {
  display: flex;
  align-items: center;
}
.form_content form,
.form_content .contact_details {
  flex: 1;
}
.form_content form {
  width: 100%;
  padding: 2rem 0 1rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.form_group {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.form_group div {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 18px;
  font-weight: 500;
}
.form_group label {
  margin: 0;
  font-size: 16px;
  padding-left: 0.5rem;
  padding-bottom: 0.5rem;
}
.form_group label span {
  color: red;
  font-size: 18px;
}

.form_content form input {
  width: 100%;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 1rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
  outline: none;
  font-size: 18px;
}
.form_content input:hover {
  border: 1px solid var(--color-primary);
  background: transparent;
}
.form_content input:focus {
  border: 1px solid var(--color-primary);
  background: transparent;
}

.form_content input::placeholder {
  font-size: 16px;
}
.form_content form input[type='submit'] {
  width: 30%;
  background: var(--color-primary);
  color: white;
  text-align: center;
}
.form_content form input[type='submit']:hover {
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  background: white;
  color: var(--color-primary);
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}
.form_content p {
  color: #ff3333;
  width: 100%;
  text-align: right;
  font-size: 16px;
}
.contact_details {
  align-self: flex-start;
  width: 100%;
  padding: 4rem 1rem 1rem 0;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.contact_icon {
  width: 50%;
}
.contact_details p {
  text-align: center;
  color: #333;
  font-size: 20px;
}
.mobile_number a {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0 0 2rem 0;
}
.mobile_number span {
  display: flex;
  align-items: center;
  justify-content: center;
}
.mobile_number svg {
  width: 2rem;
  height: 2rem;
  margin: auto 0;
  color: var(--color-primary);
}
.mobile_number p {
  padding: 0;
  margin: 0;
  font-size: 22px;
}
.div_number {
  width: 100%;
  margin-top: 1rem;
  position: relative;
}
.div_number span {
  position: absolute;
  left: 2px;
}

@media (max-width: 650px) {
  .contact_section {
    height: auto;
    padding: 2rem 0.5rem;
  }
  .form_content {
    flex-direction: column-reverse;
  }
  .form_content form input {
    width: 100%;
  }
  .form_content form input[type='submit'] {
    width: 50%;
  }
}
