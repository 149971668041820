.why .col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
  padding: 0;
}
.why_container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(45%, 1fr));
  grid-gap: 4rem;
}

.why .box-icon-3 {
  padding: 1rem 0 0;
  width: 100%;
  box-shadow: 0px 11px 9px 1px rgba(89, 164, 250, 0.1);
  border: 1px solid #ddd;
}
.why .box-icon-3:hover {
  box-shadow: 0px 3px 15px 2px rgba(0, 0, 0, 0.71);
}
.why .box-icon-3:hover .title:after {
  width: 100%;
}
.why .box-icon-3 .text {
  padding: 0 0 0 1rem;
}

@media (max-width: 765px) {
  .why {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .why .box-icon-3 {
    padding: 20px;
  }
  .why .col-xs-1,
  .col-sm-1,
  .col-md-1,
  .col-lg-1,
  .col-xs-2,
  .col-sm-2,
  .col-md-2,
  .col-lg-2,
  .col-xs-3,
  .col-sm-3,
  .col-md-3,
  .col-lg-3,
  .col-xs-4,
  .col-sm-4,
  .col-md-4,
  .col-lg-4,
  .col-xs-5,
  .col-sm-5,
  .col-md-5,
  .col-lg-5,
  .col-xs-6,
  .col-sm-6,
  .col-md-6,
  .col-lg-6,
  .col-xs-7,
  .col-sm-7,
  .col-md-7,
  .col-lg-7,
  .col-xs-8,
  .col-sm-8,
  .col-md-8,
  .col-lg-8,
  .col-xs-9,
  .col-sm-9,
  .col-md-9,
  .col-lg-9,
  .col-xs-10,
  .col-sm-10,
  .col-md-10,
  .col-lg-10,
  .col-xs-11,
  .col-sm-11,
  .col-md-11,
  .col-lg-11,
  .col-xs-12,
  .col-sm-12,
  .col-md-12,
  .col-lg-12 {
    width: 100%;
  }
}
@media (max-width: 600px) {
  .why_container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .why_container .box-icon-3 {
    width: 95%;
  }
}

@media (max-width: 500px) {
  .why .box-icon-3 {
    padding: 10px;
  }
}
