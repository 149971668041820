.adv_section {
  /* display: flex;
  align-items: baseline;
  gap: 1rem;
  flex-wrap: wrap; */
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
  gap: 1rem;
  height: auto;
  width: 100%;
}
@media (max-width: 765px) {
  .adv_section {
    grid-template-columns: repeat(2, minmax(30%, 1fr));
  }
}
@media (max-width: 500px) {
  .adv_section {
    grid-template-columns: repeat(1, minmax(100%, 1fr));
  }
}

.adv_item {
  position: relative;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  border: 1px solid var(--color-primary);
  border-radius: 1rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
  min-height: 210px;
  box-shadow: 0px 11px 9px 1px rgba(89, 164, 250, 0.1);
}
.adv_item::before {
  content: '';
  height: 2px;
  width: 30px;
  display: block;
  margin: 4% 0;
  background: var(--color-primary);
  transition: width 0.5s ease-in-out;
  -webkit-transition: width 0.5s ease-in-out;
  -moz-transition: width 0.5s ease-in-out;
  -ms-transition: width 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out;
}
.adv_item:hover::before {
  width: 100%;
}

.adv_item h4 {
  color: var(--color-secondary);
  font-size: 20px;
}
.adv_item p {
  font-size: 16px;
  color: #101010;
}
