.swiper_container {
  padding: 1rem 0 0 0;
  position: relative;
}

.swiper_container .swiper-slide {
  width: 95%;
  /* height: 50rem; */
  position: relative;
}
.swiper_container .swiper-slide img {
  height: 100%;
}

@media (max-width: 500px) {
  .swiper_container .swiper-slide {
    width: 100%;
  }
  /*.swiper_container .swiper-slide {
    width: 28rem !important;
    height: 36rem !important;
  }
  .swiper_container .swiper-slide img {
    width: 28rem !important;
    height: 36rem !important;
  } */
}

.swiper_container .swiper-slide img {
  width: 100%;
  height: 100%;
  border-radius: 2rem;
  object-fit: cover;
}

.swiper_container .swiper-slide-shadow-left,
.swiper_container .swiper-slide-shadow-right {
  display: none;
}

.swiper_container .slider-controler {
  position: relative;
  bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper_container .slider-controler .swiper-button-next {
  left: 58% !important;
  transform: translateX(-58%) !important;
}

@media (max-width: 990px) {
  .swiper_container .slider-controler .swiper-button-next {
    left: 70% !important;
    transform: translateX(-70%) !important;
  }
}

@media (max-width: 450px) {
  .swiper_container .slider-controler .swiper-button-next {
    left: 80% !important;
    transform: translateX(-80%) !important;
  }
}

@media (max-width: 990px) {
  .swiper_container .slider-controler .swiper-button-prev {
    left: 30% !important;
    transform: translateX(-30%) !important;
  }
}

@media (max-width: 450px) {
  .swiper_container .slider-controler .swiper-button-prev {
    left: 20% !important;
    transform: translateX(-20%) !important;
  }
}

.swiper_container .slider-controler .slider-arrow {
  background: var(--white);
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  left: 42%;
  transform: translateX(-42%);
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.slider-controler .slider-arrow ion-icon {
  font-size: 2rem;
  color: #222224;
}

.swiper_container .slider-controler .slider-arrow::after {
  content: '';
}

.swiper_container .swiper-pagination {
  position: relative;
  width: 15rem !important;
  bottom: 1rem;
}

.swiper_container .swiper-pagination .swiper-pagination-bullet {
  background: #2c2663;
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
  -webkit-filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.swiper_container .swiper-pagination .swiper-pagination-bullet-active {
  background: #00a0de;
}
