@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800;900&display=swap');
:root {
  --color-primary: #00a0de;
  --color-secondary: #2c2663;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Tajawal', sans-serif;
}
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background-color: inherit;
}
::-webkit-scrollbar-thumb {
  background-color: var(--color-primary);
  border-radius: 1rem;
  border-top-right-radius: 1rem;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.h1 {
  font-size: 2.5rem;
}
.h2 {
  font-size: 2rem;
}
.h3 {
  font-size: 1.5rem;
}
.h4 {
  font-size: 1.2rem;
}
.h5 {
  font-size: 1rem;
}
.h6 {
  font-size: 0.8rem;
}
.body1 {
  font-size: 1rem;
}
.body2 {
  font-size: 0.8rem;
}
.caption {
  font-size: 0.6rem;
}

.p10 {
  padding: 10px;
}
.p20 {
  padding: 20px;
}
.p30 {
  padding: 30px;
}
.p40 {
  padding: 40px;
}
.p50 {
  padding: 50px;
}
.pt10 {
  padding-top: 10px;
}
.pb10 {
  padding-bottom: 10px;
}
.pt20 {
  padding-top: 20px;
}
.pb20 {
  padding-bottom: 20px;
}
.pt30 {
  padding-top: 30px;
}
.pb30 {
  padding-bottom: 30px;
}
.pt40 {
  padding-top: 40px;
}
.pb40 {
  padding-bottom: 40px;
}
.pt50 {
  padding-top: 50px;
}
.pb50 {
  padding-bottom: 50px;
}
.mtb10 {
  margin: 10px 0;
}
.mtb20 {
  margin: 20px 0;
}

.mb20 {
  margin-bottom: 20px;
}

.link_Active {
  color: var(--color-primary);
}

.custom_dropdown {
  height: 60px;
  padding: 1rem;
}
.dropdown-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-size: 16px;
  background: transparent;
  border: none;
  border-radius: 4px;
  height: 40px;
  width: 100%;
  padding: 0;
  cursor: pointer;
}
.dropdown-btn svg {
  width: 3rem;
  height: 3rem;
}

.dropdown-items {
  background: var(--color-primary);
  width: 100px;
  position: absolute;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  color: #fff;
  left: 0;
  z-index: 9999;
}
.dropdown-item {
  padding-top: 0px;
  padding-bottom: 0px;
  cursor: pointer;
  padding: 0 10px;
}
.dropdown-item:hover {
  background: rgba(0, 0, 0, 0.1);
}
.dropdown-item:not(first-child) {
  padding-top: 15px;
  padding-bottom: 15px;
}
.dropdown-item:not(last-child) {
  padding-top: 15px;
  padding-bottom: 15px;
}

.isVisible {
  visibility: visible;
}
.isHidden {
  visibility: hidden;
}

.svg {
  width: 100%;
  color: white;
}
.hero {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}
.hero p {
  text-align: center;
  width: 80%;
  border: 1px solid var(--color-primary);
  color: var(--color-secondary);
  padding: 2rem 1rem;
  border-radius: 1rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
  font-size: 2rem;
  font-weight: 600;
  line-height: 30px;
}

@media (max-width: 500px) {
  .dropdown-btn {
    font-size: 12px;
    font-weight: normal;
  }
  .dropdown-btn span {
    display: none;
    width: 3rem;
  }
  /* ul,
  ol {
    margin: 0;
  } */
}
