.nav_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  width: 100%;
}
.nav_flex nav ul:first-child {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
}
.logo img {
  height: 100%;
}

.contact_nav {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.contact_nav a {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--color-secondary);
  text-decoration: none;
  font-size: 14px;
}
.contact_nav span {
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact_nav svg {
  width: 2rem;
  height: 2rem;
  margin: auto 0;
  color: var(--color-primary);
}

.call_div {
  display: flex;
  gap: 0.5rem;
}

a {
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: 0.6px;
  line-height: 30px;
}

.dropdown-menu li {
  text-align: right;
}
.link_Active {
  color: var(--color-primary);
  font-weight: 500;
  font-size: 18px;
  position: relative;
}
.navbar-main .caret {
  margin-left: 0;
}

.navbar-main .caret {
  margin-left: 0;
}
@media (max-width: 996px) {
  .contact_nav {
    display: none;
  }
}
@media (max-width: 664px) {
  .navbar-brand {
    display: none;
  }
  .nav_flex nav {
    width: 100%;
  }
}

@media (max-width: 770px) {
  .navbar-nav .open .dropdown-menu {
    position: absolute;
    right: 0;
    background: var(--color-primary);
  }
  .navbar-nav .open .dropdown-menu li a {
    color: white;
  }
}
@media (max-width: 500px) {
  .container-nav {
    margin: 0;
  }
  .nav > li > a {
    padding: 0;
  }
  .navbar-main .navbar-brand > img {
    width: auto;
  }
  .navbar-main .navbar-brand {
    padding: 0;
  }

  .nav_flex nav ul:first-child {
    gap: 5px;
  }

  .navbar-nav .open .dropdown-menu {
    position: absolute;
    right: 0;
    background: var(--color-primary);
  }
}
@media (max-width: 480px) {
  .nav_flex nav ul:first-child {
    gap: 0.5rem;
    flex-direction: row;
    margin: 0;
  }
  .navbar-nav .open .dropdown-menu {
    position: absolute;
    right: 0;
    background: var(--color-primary);
  }

  .custom_dropdown {
    padding: 1rem 0;
  }
}
@media (max-width: 405px) {
  .dropdown a {
    font-size: 14px;
  }
}
@media (max-width: 385px) {
  .dropdown a {
    font-size: 12px;
  }
}
