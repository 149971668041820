.faq_title {
  font-size: 25px;
  font-weight: 700;
  text-align: center;
  text-decoration: underline;
  color: var(--color-primary);
}
.faq_desc {
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  /* text-align: center; */
  color: var(--color-secondary);
  padding-right: 1rem;
}
.faq_item {
  padding-right: 1rem;
}
.faq_item_title {
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  color: var(--color-secondary);
}
.faq_item_desc {
  list-style-type: decimal;
  padding-right: 1rem;
}

.faq_item_desc li {
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  color: #333;
}
.faq_item_desc li span {
  color: #000;
  text-decoration: underline;
  font-weight: 600;
}
