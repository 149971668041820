.footer_container {
  display: grid;
  grid-template-columns: 30% 30% 25% auto;
  grid-template-rows: auto;
  column-gap: 2rem;
  grid-template-areas: 'logo titles pages social';
}

.footer_container_item {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.titles_area .footer_item_group {
  padding: 1rem 0;
}
.footer_container_item p {
  font-size: 16px;
  padding: 1rem;
  line-height: 25px;
}
.footer_container_item .footer-title {
  font-size: 20px;
  font-weight: 700;
  padding: 20px 0 20px 0;
  margin-bottom: 10px;
  letter-spacing: -0.06em;
}
.footer_item_group {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.footer_item_group a {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.footer_item_Icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer_item_Icon svg {
  width: 2rem;
  height: 2rem;
  color: var(--color-primary);
}
.footer_container_item .list {
  list-style-type: square;

  padding-right: 2rem;
}
.list li::marker {
  color: var(--color-primary);
}

.footer_item_text {
  font-size: 16px;
  color: 16px;
  color: var(--color-secondary);
  font-weight: 500;
  letter-spacing: 0.6px;
  line-height: 30px;
}
.footer_logo {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer_logo img {
  width: 300px;
  height: 100%;
}
.footer a {
  font-size: 16px;
  color: var(--color-secondary);
}
.logo_area {
  grid-area: logo;
}
.titles_area {
  grid-area: titles;
}
.pages_area {
  grid-area: pages;
}
.social_area {
  grid-area: social;
}

@media (max-width: 670px) {
  .footer_container {
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    grid-template-areas:
      'logo logo'
      'titles pages'
      'social social';
  }
  .ftex {
    padding: 0 3rem 0 0;
  }
  .footer .footer-sosmed {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}
@media (max-width: 550px) {
  .footer_container {
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    grid-template-areas:
      'logo logo'
      'pages pages'
      'titles titles'
      'social social';
  }
  .ftex {
    padding: 0 3rem 0 0;
  }
  .footer_container_item {
    padding-right: 2rem;
  }
}
