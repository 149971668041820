.customers_title {
  letter-spacing: 0.6px;
}
.customers {
  /* width: 100%; */
  height: auto;
  margin: 1rem 0;
}
.customers .swiper-wrapper {
  height: 10rem;
}
.customers .swiper-wrapper .swiper-slide {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.customers .swiper-wrapper .swiper-slide img {
  width: auto;
  height: 100%;
  margin: auto 0;
}
